import { Button } from "antd";
import React from "react";

const Service = () => {
  return (
    <section className="p-20 flex justify-center">
      <div className="container">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4">
            <div className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20">
              <span className="block mb-2 text-lg font-semibold text-orange-500 ">
                Our Services
              </span>
              <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px]">
              Need Any Help?
              </h2>
              <p className="text-base text-body-color">
              Our Support executives are a group of skilled professionals who thrive on assisting clients and candidates with their queries and concerns.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap -mx-4">
          <ServiceCard
            title="Chat to Us Online"
            details="Chat to us online if you have any question."
            icon="/images/phone-call.svg"
            btn="Chat Us"
          />
          <ServiceCard
            title="Our Support executive"
            details="Our support executive will work with you to meets."
            icon="./images/phone-call.svg"
            btn="Contact Us"
          />
          <ServiceCard
            title="Mail to Us"
            details="Mail to Us if you have any queries."
            icon="./images/email_1.svg"
            btn="Mail Us"
          />
     
        </div>
      </div>
    </section>
  );
};

export default Service;

const ServiceCard = ({ icon, title, details,btn } : {icon:any, title:string; details:string;btn:string}) => {
  return (
    <>
      <div className="w-full  px-4 md:w-1/2 lg:w-1/3">
        <div className="mb-8 min-h-[310px] rounded-[20px] bg-white p-6 shadow-md hover:shadow-lg md:px-7 xl:px-10">
          <div
            className={`mb-6 mt-6 flex h-[70px] w-[110px] p-6 items-center justify-center rounded-2xl bg-primary m-auto`}
          >
           <img src={icon} alt="" /> 
          </div>
          <h4 className="mb-3 text-xl font-semibold text-dark">{title}</h4>
          <h5 className="text-body-color min-h-[40px]">{details}</h5>
          {/* <Button className="bg-orange-600 text-white mt-6 mb-6">{btn}</Button> */}
          <Button className="inline-block py-1 text-sm font-semibold transition border rounded-md px-6 text-body-color hover:border-primary bg-orange-600 text-white hover:bg-blue-700 hover:text-white mt-6 mb-6">{btn}</Button>
        </div>
      </div>
    </>
  );
};
