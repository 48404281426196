import { Button } from "antd";
import React, { useEffect, useState } from "react";

interface Job {
  Job_Title: string;
  _id: string;
  Company_Name: string;
  Job_Type: string;
  Location:string;
  Description:string;
}

const RecentJobs: React.FC = () => {
  const [recentjobs, setRecentjobs] = useState<Job[]>([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch('http://209.182.232.22:4000/findjobs', {
          method: 'POST', // Specify the HTTP method
          headers: {
            'Content-Type': 'application/json', // Set the appropriate content type
          },
          body: JSON.stringify({ /* Your POST data here */ }), // Replace with your data
        });
  
        if (response.ok) {
          const allJobs = await response.json();
          setRecentjobs(allJobs.data);
          console.log('Data fetched:', allJobs.data);
        } else {
          console.log('Error fetching data:', response.status, response.statusText);
        }
      } catch (error) {
        console.log('Error:', error);
      }
    };
  
    fetchJobs();
  }, []);

  return (
    <div className="w-full px-12 pb-12">
      <h2 className="p-4">Recent Jobs</h2>
      <hr />
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          {/* <h2>Column 1</h2> */}
          <ul>
            {recentjobs.slice(0, 5).map((job) => (
              <li key={job._id}>
                <div className="mx-auto w-[80%] p-6 shadow-lg">
                  {" "}
                  <h4>{job.Job_Title}</h4>
                  <h6 className="text-left">{job.Description}</h6>
                  <Button className="bg-blue-700 text-white">Apply Now</Button>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div style={{ flex: 1 }}>
          {/* <h2>Column 2</h2> */}
          <ul>
            {recentjobs.slice(5, 10).map((job) => (
              <li key={job._id}>
                <div className="mx-auto w-[80%] p-6 shadow-lg">
                  {" "}
                  <h4>{job.Job_Title}</h4>
                  <h6 className="text-left">{job.Description}</h6>
                  <Button className="bg-blue-700 text-white">Apply Now</Button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RecentJobs;
