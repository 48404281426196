
import RecentJobs from "./RecentJobs";
import StickyNavbar from "./StickyNavbar";
import {useState,useEffect} from "react"
import axios from "axios";

const UserDashboard = () => {
    const [profiledata, setProfileData] = useState({UserID:'',Email:'',Firstname:'', Lastname:'',Phone:'',Title:'',Username:'',Address:'',Applied_Jobs:'',Shorlisted_Jobs:'',Pending_Applications:'',Scheduled_Applications:'', City:'',State:'',Area:'',Pincode:''});
    const email = localStorage.getItem("email");
    useEffect(() => {
        // Define an async function to handle the axios request
        const fetchData = async () => {
          try {
            const response = await axios.get('http://209.182.232.22:4000/profile', {
              params: {
                email: email, // Replace with the actual email address
              },
            });
            const data = response.data;
            console.log(data);
            setProfileData(data);
          } catch (error) {
            console.error('Error fetching profile data:', error);
          }
        };
    
        fetchData();
      }, []);

  return (
    <div>
<StickyNavbar />
<div className="grid grid-cols-2">
  <div className="profile">
<div className="flex items-center py-20 w-full justify-center ">

<div className="w-[600px] ">
    <div className="bg-white shadow-xl rounded-lg py-3 max-w-[550px]">
        <div className="photo-wrapper p-2">
            <img className="w-32 h-32 mx-auto rounded-full" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9Sv-om9B5JxNG0cochsNaQaAsNB8UGyrXWQ0wKzA&s" alt="User" />
        </div>
        <div className="p-6 text-center">
        <h3 className="px-2 text-center text-xl text-gray-900 font-medium leading-8">
  {profiledata.Firstname && profiledata.Lastname
    ? `${profiledata.Firstname} ${profiledata.Lastname}`
    : profiledata.Username}
</h3>
 <div className="px-2 text-center text-gray-400 text-xs font-semibold">
                <p>{profiledata.Title}</p>
            </div >
            <table className="text-xs my-3 mx-auto">
                <tbody><tr>
                    <td className="px-2 py-2 text-gray-500 font-semibold">Address</td>
                    <td className="px-2 py-2"><h6>{profiledata.Area}, {profiledata.City}</h6>
                    <h6>{profiledata.State}, {profiledata.Pincode}</h6></td>
                </tr>
                <tr>
                    <td className="px-2 py-2 text-gray-500 font-semibold">Phone</td>
                    <td className="px-2 py-2">{profiledata.Phone}</td>
                </tr>
                <tr>
                    <td className="px-2 py-2 text-gray-500 font-semibold">Email</td>
                    <td className="px-2 py-2">{profiledata.Email}</td>
                </tr>
            </tbody></table>

            

        </div>
    </div>
</div>

</div>
  </div>
<div className="p-6" >
<div className="flex items-center bg-white-700">
    <div className="container max-w-6xl px-5 mx-auto my-28">
        <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-2">
            <div className="p-5 bg-white rounded shadow-sm dark:bg-gray-800">
                <div className="text-base text-gray-400 dark:text-gray-300 text-left">Number of Applications</div>
                <div className="flex items-center pt-1">
                    <div className="text-2xl font-bold text-gray-900 dark:text-gray-100">{profiledata.Applied_Jobs}</div>
                    
                </div>
            </div>
            <div className="p-5 bg-white rounded shadow-sm dark:bg-gray-800">
                <div className="text-base text-gray-400 dark:text-gray-300 text-left">Shortlisted Applications</div>
                <div className="flex items-center pt-1">
                    <div className="text-2xl font-bold text-gray-900 dark:text-gray-100">{profiledata.Shorlisted_Jobs}</div>
                    
                </div>
            </div>
            <div className="p-5 bg-white rounded shadow-sm dark:bg-gray-800">
                <div className="text-base text-gray-400 dark:text-gray-300 text-left">Pending Applications</div>
                <div className="flex items-center pt-1">
                    <div className="text-2xl font-bold text-gray-900 dark:text-gray-100">{profiledata.Pending_Applications}</div>
                    
                </div>
            </div>
            <div className="p-5 bg-white rounded shadow-sm dark:bg-gray-800">
                <div className="text-base text-gray-400 dark:text-gray-300 text-left">Scheduled Applications</div>
                <div className="flex items-center pt-1">
                    <div className="text-2xl font-bold text-gray-900 dark:text-gray-100">{profiledata.Scheduled_Applications}</div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
<RecentJobs />
    </div>
  );
};

export default UserDashboard;