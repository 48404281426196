import { Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation from react-router-dom
import SearchInput from './SearchInput';
import SearchResult from './SearchResult';

interface Job {
  Job_Title: string;
  _id: string;
  Company_Name: string;
  Job_Type: string;
  Location:string;
  Description:string;
  Job_ID:string;
}

function Carrer() {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [searchinput, setSearchInput] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(10);

  const location = useLocation(); // Get the current location

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await fetch('http://209.182.232.22:4000/findjobs', {
          method: 'POST', // Specify the HTTP method
          headers: {
            'Content-Type': 'application/json', // Set the appropriate content type
          },
          body: JSON.stringify({ /* Your POST data here */ }), // Replace with your data
        });
  
        if (response.ok) {
          const allJobs = await response.json();
          setJobs(allJobs.data);
          console.log('Data fetched:', allJobs.data);
        } else {
          console.log('Error fetching data:', response.status, response.statusText);
        }
      } catch (error) {
        console.log('Error:', error);
      }
    };
  
    fetchJobs();
  }, []);
  
  

  // Parse the "title" query parameter from the URL
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const title = queryParams.get('title');
    if (title) {
      setSearchInput(title);
    }

  }, [location]);

  const filteredJobs = jobs.filter((job) =>
    job.Company_Name.toLowerCase().includes(searchinput.toLowerCase()) ||
    job.Job_Title.toString().includes(searchinput) || 
    job.Location.toString().includes(searchinput)
  );

  // Calculate the indices for pagination
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);

  // Function to handle page change
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className='p-6'>
      <SearchInput searchInput={searchinput} setSearchInput={setSearchInput} />
      <SearchResult jobs={filteredJobs} currentPage={currentPage} jobsPerPage={jobsPerPage} paginate={paginate} />
    </div>
  );
}

export default Carrer;
