import axios from 'axios';
import { MDBBtn, MDBCol, MDBContainer, MDBRow, MDBSpinner } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ToastContainer,toast } from 'react-toastify';

const ViewJob = () => {
  const { jobid } = useParams();
  const [jobDetails, setJobDetails] = useState({
    Imgurl: '', // Provide initial values for the properties you expect
    Company_Name: '',
    Job_Title: '',
    Description: '',
  });
  const token = localStorage.getItem('token');
  const email = localStorage.getItem('email');
  const navigate = useNavigate();

  useEffect(() => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${token}`);
    if (email) {
      headers.append('Email', email);
    }

    fetch(`http://209.182.232.22:4000/jobs/${jobid}`, {
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch job details');
        }
        return response.json();
      })
      .then((data) => {
        setJobDetails(data);
      })
      .catch((error) => {
        console.error('Error fetching job details:', error);
      });
  }, [jobid, token, email]);

  const applyjob = async () => {
    if (!email || !token) {
      toast.error('Please login first to apply for the role.');
      setTimeout(() => {
        navigate('/login');
      }, 4000);
      return;
    }
  
    const confirmation = window.confirm('Are you sure you want to submit this job application?');
  
    if (confirmation) {
      try {
        const response = await axios.post('http://209.182.232.22:4000/apply', {
          jobid,
          email,
          token,
        });
  
        if (response.status === 201) {
          toast.success('Job application submitted successfully');
          setTimeout(() => {
            navigate('/dashboard');
          }, 4000);
        } else {
          throw new Error('Failed to submit job application please verify your profile first');
        }
      } catch (error) {
        console.error('Error submitting job application:', error);
        toast.error('Failed to submit job application please verify your profile first');
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <MDBContainer>
        <MDBRow className='p-12'>
          <MDBCol>
            {jobDetails ? (
              <div className='text-left'>
                <img src={jobDetails.Imgurl} alt="" style={{ width: "100px" }} />
                <h2>Company Name: {jobDetails.Company_Name}</h2>
                <h2>Job Title: {jobDetails.Job_Title}</h2>
                <p>Description: {jobDetails.Description}</p>
                <MDBBtn className='mb-4 w-100 border-2 border-gray-100 focus:outline-none bg-blue-700 text-white font-bold tracking-wider block  p-2 rounded-lg focus:border-gray-700 hover:bg-purple-700' onClick={applyjob}>
                  Submit
                </MDBBtn>
              </div>
            ) : (
              <div className="text-center" style={{ minHeight: '100vh', backgroundColor: '#f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <MDBSpinner grow color="primary" />
                <p>Loading job details...</p>
              </div>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};


export default ViewJob;
