import React from 'react'
import About from './Pages/About'

const Aboutus = () => {
  return (
    <div>
        <About />
    </div>
  )
}

export default Aboutus