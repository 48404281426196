import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

interface ImageResultProps {
  imageUrl: string;
  text: string;
}

const ImageResult: React.FC<ImageResultProps> = ({ imageUrl, text }) => {
  return (
    <div className="image-result-card rounded-lg">
      <img src={imageUrl} alt={text} className='p-6' style={{borderRadius:'40px'}}/>

        <Link
  className="inline-block py-2 text-sm font-semibold transition border rounded-md px-6 text-body-color hover:border-primary bg-orange-600 text-white hover:bg-blue-700 hover:text-white"
  to={`/carrer?title=${text}`}
>
 {text}
</Link>

    </div>
  );
};

export default ImageResult;
