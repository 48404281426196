import React from 'react';
import './css/Newsletter.css';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';


function Newsletter() {
    return (
        <div className='Newsletter-sec'>
<MDBContainer>
    <MDBRow>
    <MDBCol md='6'>
          <h2>Receive Solutions to Any Queries You Might Pose Pleased to be of help!</h2>
          <p>We will answer any questions you may have about our online sales.</p>
        </MDBCol>
    <MDBCol className='newletter-btn md-6'>
      <button> <a href="">Get Quote</a></button>
    </MDBCol>
    </MDBRow>
</MDBContainer>
</div>
        )
    }
    
    export default Newsletter