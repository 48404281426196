import React, { useEffect, useState } from "react";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn } from "mdb-react-ui-kit";
import axios from "axios";
import { Button } from "@material-tailwind/react";


function ItemDetailsModal(props: any) {
  const { item, closeModal } = props;
  const [applicationDetails, setApplicationDetails] = useState({ _id: '', UserID: '', Job_ID: '', Applied_Time:'' });
  const [userdetails,setUserDetails] = useState({ UserID: '', Email: '', Firstname: '', Lastname: '', Phone: '', Title: '', Username: '', City: '', State: '', Pincode : '', Area :'' });
  const [jobdetails,setJobDetails] = useState({Job_Title: '', Job_Type: '', Location: '', Description: '', });
  useEffect(() => {
    if (item) {
        axios.get(`http://209.182.232.22:4000/applicationdetails/${item.UserID}/${item.Job_ID}`)
        .then((response) => {
          setApplicationDetails(response.data);
          // console.log(response.data);
          setUserDetails(response.data.User);
          setJobDetails(response.data.JobDetails);
        })
        .catch((error) => {
          console.error("Error fetching application details:", error);
        });
    }
  }, [item]);

  return (
    <MDBModal show={!!item} tabIndex="-1" backdrop={true} keyboard={true}>
      <MDBModalHeader>Application Details</MDBModalHeader>
      <hr />
      <MDBModalBody>
        {item && applicationDetails && (
          <div>
            <p>UserID: {item.UserID}</p>
            <p>Full Name: {userdetails.Firstname} {userdetails.Lastname}</p>
            <p>Applied Time: {new Date(item.Applied_Time).toLocaleString()}</p>
            <p>Address : {userdetails.Area}, {userdetails.City}, {userdetails.State}, {userdetails.Pincode}</p>
        <hr />
        <div>
        <p>Job Title: {jobdetails.Job_Title}</p>
            <p>Job Type: {jobdetails.Job_Type}</p>
            <p>Job Description: {jobdetails.Description}</p>
        </div>
          </div>
        )}
<div className="jutify-between flex gap-5">
<MDBBtn className="bg-green-600 text-white p-2 rounded-lg">Shortlist</MDBBtn>
<MDBBtn className="bg-red-600 text-white p-2 rounded-lg">Reject</MDBBtn>
<MDBBtn className="bg-red-700 text-white p-2 rounded-lg" onClick={closeModal}>
Close
          </MDBBtn>
</div>

         
      </MDBModalBody>
    
    </MDBModal>
  );
}

export default ItemDetailsModal;
