import React, { useEffect, useState } from 'react';
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import AdminNavbar from './AdminNavbar';
import axios from 'axios';
import ItemDetailsModal from './ItemDetailsModal';
import { MDBBtn, MDBModal } from 'mdb-react-ui-kit';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'antd';

const Users = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [adminData, setAdminData] = useState([]);
  const [rowData,setRowdata] = useState([]);
  const email = localStorage.getItem('email');
  const token = localStorage.getItem('token');
  
    useEffect(() => {
        if (!email || !token) {
          console.error('Email and token are required for validation');
          return;
        }
    
        // Create a data object with email and token
        const data = {
          email,
          token,
        };

        axios.post('http://209.182.232.22:4000/admindata', data) // Use Axios post request
          .then((response) => {
            if (!response.data) {
              throw new Error('Failed to fetch admin data');
            }
            console.log(response.data);
          
            const rowDataWithView = response.data.map((item:any) => ({
              ...item,
              View: <button className='bg-blue-600' onClick={() => handleViewClick(item)}>View</button>,
            }))
            setAdminData(response.data);
            setRowdata(rowDataWithView);
          })
          .catch((error) => {
            console.error('Error fetching admin data:', error);
          });
      }, [email, token]);

      const handleViewClick = (item:any) => {
        setSelectedItem(item);
        setModalOpen(true);
      };

      const closeModal = () => {
        setModalOpen(false);
      };
      const [columnDefs] = useState<ColDef[]>([
        { headerName: "UserID", field: "UserID" },
        { headerName: "Username", field: "Username" },
        { headerName: "Job_ID", field: "Job_ID" },
        { headerName: "Applied_Time", field: "Applied_Time" },
        { headerName: "Job_Description", field: "Job_Description" },
        {
          headerName: "View",
          field: "View",
          cellRenderer: (params:any) => {
            return <MDBBtn className='bg-blue-600 text-white px-6 relative' onClick={() => handleViewClick(params.data)}>View</MDBBtn>;
          },
          sortable: false,
          filter: false,
        },
      ]);
      
  

  const defaultColDef = {
    cellStyle: { textAlign: "left" },
  };

  return (
    <>

<Modal show={isModalOpen} onHide={closeModal} className='modalpop'>
        <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body>
          <ItemDetailsModal item={selectedItem} closeModal={closeModal} />
        </Modal.Body>
        
      </Modal>

      <div className="pt-16 px-16">
      <hr />
        <h2>Total Users</h2>
        <div className="ag-theme-alpine pt-4 px-16" style={{ height: 400, width: '100%' }}>
          <AgGridReact 
          rowData={rowData} 
          columnDefs={columnDefs} 
          defaultColDef={defaultColDef} 
          paginationAutoPageSize={true} 
          pagination={true} 
          pivotPanelShow={'always'}></AgGridReact>
        </div>
      </div>
    </>
  );
}

export default Users;


