import React from 'react'
import { MailOutlined, FacebookFilled } from '@ant-design/icons';

const Topbar = () => {
  return (
    <div className='bg-orange-600 py-2 '>
        <div className="grid grid-cols-1 gap-4 text-white ">
            <div className='flex gap-2 font-poppins pl-8 font-bold justify-center'>
                <h4 className='font-poppins'>Empowering Futures: Where Aspirations Soar on the Wings of Opportunity</h4>
             
            </div>
           
            
        </div>
    </div>
  )
}

export default Topbar