import { Button } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Joblist = () => {
  const [showCard, setShowCard] = useState("all");

  const [val, setValu] = useState(8);
  return (
    <>
      <section className="p-10 bg-white flex justify-center">
        <div className="container">
          <div className="flex flex-wrap -mx-4">
            <div className="w-full px-4">
              <div className="mx-auto mb-[60px] max-w-[810px] text-center">
                <span className="block mb-2 text-orange-500 text-lg font-semibold text-primary">
                Latest Jobs Post
                </span>
                <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px]">
                Jobs You May Be Interested
                </h2>
                <h4>Discover Your Next Adventure! Explore Jobs Tailored to Your Passion and Expertise – Your Perfect Opportunity Awaits</h4>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2">
            <Joblisting
              image="./images/emplogo1.jpg"
              company="For a Top Notch Healthcare Organisation"
              title="Healthcare"
              button="View Details"
              showCard={showCard}
            />
            <Joblisting
               image="./images/emp-logo2.jpg"
              company="For one of the renowned Bio Tech Company"
              title="Bio Technology"
              button="View Details"
              showCard={showCard}
            />
            <Joblisting
              image="./images/emp-logo3.jpg"
              company="For a renowned Green Energy Company"
              title="Renewable Energy"
              button="View Details"
              showCard={showCard}
            />
            <Joblisting
              image="./images/emp-logo4.jpg"
              company="For a leading Fin Tech Company"
              title="Financial Technology (FinTech)"
              button="View Details"
              showCard={showCard}
            />
            <Joblisting
              image="./images/emp-logo5.jpg"
              company="For leading Automobile Manufacturer"
              title="Research & Development (R&D)"
              button="View Details"
              showCard={showCard}
            />
            <Joblisting
              image="./images/emp-logo6.jpg"
              company="For a leading Business Conglomerates"
              title="C Level (CXO’s)"
              button="View Details"
              showCard={showCard}
            />
             <Joblisting
              image="./images/emp-logo7.jpg"
              company="For one of the biggest Data Science Company"
              title="Data Science and Analytics"
              button="View Details"
              showCard={showCard}
            />
             <Joblisting
              image="./images/emp-logo8.jpg"
              company="For a Multi-National DT Company"
              title="Cybersecurity"
              button="View Details"
              showCard={showCard}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Joblist;

const Joblisting = ({
  showCard,
  company,
  title,
  button,
  image,
}: {
  showCard: any;
  company: string;
   title: string;
  button: string;
  image:any;
}) => {
  return (
    <>
      <div
        className={`w-full px-4 md:w-full ${
          showCard === "all" || showCard === company.toLowerCase()
            ? "block"
            : "hidden"
        }`}
      >
        <div className="relative mb-12 ">
          <img className="absolute h-24 right-8 top-8" src={image} alt="" style={{zIndex:'2'}} />
          <div className="relative px-3 bg-white rounded-lg shadow-lg mx-7 py-5 text-left">
            <h2 className="block mb-2 text-sm font-semibold text-primary">
              {company}
            </h2>
            <h3 className="mb-4 text-xl font-bold text-dark">{title}</h3>
            <p className="p-2"><span className="text-blue-700">Full Time</span> - <span>New York</span></p>
            <Link
  className="inline-block py-3 text-sm font-semibold transition border rounded-md px-7 text-body-color bg-orange-600 text-white hover:border-primary hover:bg-blue-700 hover:text-white"
  to={`/carrer?title=${title}`}
>
  Apply Now
</Link>
          </div>
        </div>
      </div>
    </>
  );
};
