import React, { useState } from 'react';
import AdminNavbar from './AdminNavbar';
import { MDBBtn, MDBInput, MDBTextArea } from 'mdb-react-ui-kit';
import axios from 'axios';

const Listjobs = () => {
  const [jobID, setJobID] = useState('');
  const [jobTitle, setJobtitle] = useState('');
  const [jobType, setJobType] = useState('');
  const [jobLocation, setJobLocation] = useState('');
  const [minSal, setMinsal] = useState('');
  const [maxSal, setMaxsal] = useState('');
  const [company, setCompany] = useState('');
  const [jobdesc, setJobDescription] = useState('');

  const listjob = () => {
    // Get the user's email and token from local storage
    const email = localStorage.getItem('email');
    const token = localStorage.getItem('token');

    // Create a job object with the form data
    const newJob = {
      email, // Include user's email
      token, // Include user's token
      jobID,
      jobTitle,
      jobType,
      jobLocation,
      minSal,
      maxSal,
      company,
      jobdesc,
    };

    // Make a POST request to list the job
    axios
      .post('http://209.182.232.22:4000/listjobs', newJob)
      .then((response) => {
        // Handle success, e.g., show a success message
        console.log('Job listed successfully', response.data);
      })
      .catch((error) => {
        // Handle error, e.g., show an error message
        console.error('Error listing the job:', error);
      });
  };

  return (
    <div>
        <AdminNavbar />
        <div className="p-12">
        <h1 className='pt-12'>List New Jobs</h1>
        <div className="pt-6 px-6 grid grid-cols-3">
          
        <MDBInput wrapperClass='mb-4' 
            className='bg-gray-200 border-2 border-gray-100 w-full focus:outline-none bg-gray-100 block py-2 px-4 rounded-lg focus:border-gray-700'  
            id='form1' 
            type='text' 
            placeholder='JOBID' 
            name="Location" 
            onChange={(e) => setJobID(e.target.value)}
            value={jobID} />
            <MDBInput wrapperClass='mb-4' 
            className='bg-gray-200 border-2 w-full border-gray-100 focus:outline-none bg-gray-100 block py-2 px-4 rounded-lg focus:border-gray-700'  
            id='form1' 
            type='text' 
            placeholder='Enter Job Title' 
            name="title" 
            onChange={(e) => setJobtitle(e.target.value)}
            value={jobTitle}/>
            <MDBInput wrapperClass='mb-4' 
            className='bg-gray-200 border-2 border-gray-100 w-full focus:outline-none bg-gray-100 block py-2 px-4 rounded-lg focus:border-gray-700'  
            id='form1' 
            type='text' 
            placeholder='Enter Job Type' 
            name="title" 
            onChange={(e) => setJobType(e.target.value)}
            value={jobType} />
            <MDBInput wrapperClass='mb-4' 
            className='bg-gray-200 border-2 border-gray-100 w-full focus:outline-none bg-gray-100 block py-2 px-4 rounded-lg focus:border-gray-700'  
            id='form1' 
            type='text' 
            placeholder='Enter Job Location' 
            name="Location" 
            onChange={(e) => setJobLocation(e.target.value)}
            value={jobLocation} />
            <MDBInput wrapperClass='mb-4' 
            className='bg-gray-200 border-2 border-gray-100 w-full focus:outline-none bg-gray-100 block py-2 px-4 rounded-lg focus:border-gray-700'  
            id='form1' 
            type='number' 
            placeholder='Enter Min Salary' 
            name="minsal" 
            onChange={(e) => setMinsal(e.target.value)}
            value={minSal} />
             <MDBInput wrapperClass='mb-4' 
            className='bg-gray-200 border-2 border-gray-100 w-full focus:outline-none bg-gray-100 block py-2 px-4 rounded-lg focus:border-gray-700'  
            id='form1' 
            type='number' 
            placeholder='Enter Max Salary' 
            name="Location" 
            onChange={(e) => setMaxsal(e.target.value)}
            value={maxSal} />

<MDBInput wrapperClass='mb-4' 
            className='bg-gray-200 border-2 border-gray-100 w-full focus:outline-none bg-gray-100 block py-2 px-4 rounded-lg focus:border-gray-700'  
            id='form1' 
            type='text' 
            placeholder='Enter Company Name' 
            name="company" 
            onChange={(e) => setCompany(e.target.value)}
            value={company} />
             {/* <MDBFile
            className='bg-gray-200 border-2 border-gray-100 w-full focus:outline-none bg-gray-100 block py-2 px-4 rounded-lg focus:border-gray-700'  
            id='form1' 
             placeholder='Image' 
            name="Location" 
            onChange={(e) => setJobtitle(e.target.value)}
            value={jobTitle} /> */}

        </div>
        <div className="grid px-6 mt-2">
        <MDBTextArea wrapperClass='mb-4' 
            className='bg-gray-200 border-2 border-gray-100 w-full focus:outline-none bg-gray-100 block py-2 px-4 rounded-lg focus:border-gray-700'  
            id='form1' 
            
            placeholder='Enter Job Description' 
            name="Description" 
            onChange={(e) => setJobDescription(e.target.value)}
            value={jobdesc} />
        </div>
        <div className="grid grid-cols-4 px-6 grid-center">
        <MDBBtn className='rounded-lg bg-blue-600 text-white p-2' onClick={listjob}>Submit</MDBBtn>
        </div>
        </div>
    </div>
  )
}
export default Listjobs;
