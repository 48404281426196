import { MDBBtn, MDBInput } from 'mdb-react-ui-kit';
import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Signup() {
  const [regusername, setregUsername] = useState('');
  const [regEmail, setregEmail] = useState('');
  const [regPassword, setregPassword] = useState('');
  const [confirmPassword, setConfirmPass] = useState('');

  const navigate = useNavigate();
  const handleSignup = async () => {
    console.log(regusername,regEmail,regPassword);
    if (regPassword !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    try {
      const userData = {
        username: regusername,
        email: regEmail,
        password: regPassword,
      };

      const response = await fetch('http://209.182.232.22:4000/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({regusername:regusername, regEmail:regEmail,regPassword:regPassword}),
      });

      if (response.ok) {
        toast('Signup successful');
     
        setregUsername('');
        setregEmail('');
        setregPassword('');
        setConfirmPass('');
        navigate('/login');
      } else {
        const errorData = await response.json();
        toast('Signup failed:', errorData);
      }
    } catch (error) {
      console.error('Signup failed:', error);
    }
  };
  return (
    <div>
<ToastContainer/>
<div className="bg-grey-lighter min-h-screen flex flex-col">
            <div className="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
                <div className="bg-white px-6 py-8 rounded shadow-md text-black w-full">
                    <h1 className="mb-8 text-3xl text-center">Sign up</h1>
                    <MDBInput wrapperClass='mb-4' className='bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700' label='Username' id='form1' type='text'  name="username" onChange={(e) => setregUsername(e.target.value)}/>
                  
                    <MDBInput wrapperClass='mb-4' className='bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700' label='Email' id='form1' type='email'  name="email" onChange={(e) => setregEmail(e.target.value)}/>
                  
                    <MDBInput wrapperClass='mb-4' className='bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700' label='Password' id='form1' type='password'  name="password" onChange={(e) => setregPassword(e.target.value)}/>
                  
                    <MDBInput wrapperClass='mb-4' className='bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700' label='Confirm Password' id='form1' type='password'  name="confirmpassword" onChange={(e) => setConfirmPass(e.target.value)}/>
                  
                    <MDBBtn className="mb-4 w-100 border-2 border-gray-100 focus:outline-none bg-blue-700 text-white font-bold tracking-wider block w-full p-2 rounded-lg focus:border-gray-700 hover:bg-purple-700" onClick={handleSignup}>Create Account</MDBBtn>

                   <div className="text-center text-sm text-grey-dark mt-4">
                        By signing up, you agree to the 
                        <a className="no-underline border-b border-grey-dark text-grey-dark" href="#">
                            Terms of Service
                        </a> and 
                        <a className="no-underline border-b border-grey-dark text-grey-dark" href="#">
                            Privacy Policy
                        </a>
                    </div>
                </div>

                <div className="text-grey-dark mt-6">
                    Already have an account? 
                    <a className="no-underline border-b border-blue text-blue" href="/login">
                        Log in
                    </a>.
                </div>
            </div>
        </div>
    </div>
  )
}

export default Signup