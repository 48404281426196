import React from 'react'
import Carrer from '../Pages/Carrer'
import StickyNavbar from './StickyNavbar'

const Jobs = () => {
  return (
    <div>
        <StickyNavbar/>
        <div className="lg:py-20 sm:pt-60px">
        <Carrer />
        </div>

    </div>
  )
}

export default Jobs