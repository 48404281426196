import React from 'react';
import ImageResult from './ImageResult';

interface ImageResultProps {
  imageUrl: string;
  text: string;
}

const ImageCard: React.FC = () => {
  // Define the image URLs and text content for each ImageResult
  const imageResults: ImageResultProps[] = [
    { imageUrl: '/images/Banglore.jpg', text: 'Bangalore' },
    { imageUrl: '/images/Mumbai.jpg', text: 'Mumbai' },
    { imageUrl: '/images/Dehali.jpg', text: 'Delhi' },
    { imageUrl: '/images/Hyderabad.jpg', text: 'Hyderabad' },
    { imageUrl: '/images/Chennai.jpg', text: 'Chennai' },
    { imageUrl: '/images/pune.jpg', text: 'Pune' },
  ];

  return (
    <div className='p-24'>
      <h3 className='text-orange-500'>Jobs Location</h3>
      <h1 className='font-bold'>Companies By Location</h1>
      <h4>Choose your preferred work location within our specified regions.</h4>
      <div className='grid grid-cols-3'>
        {imageResults.map((result, index) => (
          <ImageResult key={index} imageUrl={result.imageUrl} text={result.text} />
        ))}
      </div>
    </div>
  );
};

export default ImageCard;
