import React, { useEffect, useState } from 'react'
import StickyNavbar from './StickyNavbar'
import axios from 'axios'
import { MDBBtn, MDBFile, MDBInput } from 'mdb-react-ui-kit';
import { Button } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
    const [file, setFile] = useState<File | null>(null);
    const [resumeData, setResumeData] = useState<string | null>(null);
    const email = localStorage.getItem('email');
    const [userid, setUserid] = useState('');
    const [profiledata, setProfileData] = useState({ UserID: '', Email: '', Firstname: '', Lastname: '', Phone: '', Title: '', Username: '', City: '', State: '', Pincode : '', Area :'' });

    const navigate = useNavigate();
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [pno, setPno] = useState('');
    const [upTitle, setUptitle] = useState('');
    const [city, setCity] = useState('');
    const [area, setArea] = useState('');
    const [state, setState] = useState('');
    const [pincode, setPincode] = useState('');
    useEffect(() => {
        // Define an async function to handle the axios request
        const fetchData = async () => {
            try {
                const response = await axios.get('http://209.182.232.22:4000/profile', {
                    params: {
                        email: email, // Replace with the actual email address
                    },
                });
                const data = response.data;
                console.log(data);
                setProfileData(data);
                setFname(data.Firstname);
                setLname(data.Lastname);
                setPno(data.Phone);
                setUptitle(data.Title);
                setCity(data.City);
                setArea(data.Area);
                setState(data.State);
                setPincode(data.Pincode);
            } catch (error) {
                console.error('Error fetching profile data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        axios
            .get('http://209.182.232.22:4000/getresume', {
                params: {
                    email: email, // Replace with the actual email address
                },
                responseType: 'arraybuffer', // Specify binary response type
            })
            .then((response) => {
                // Create a Blob object from the binary data
                const blob = new Blob([response.data], { type: 'application/pdf' });

                // Create a URL for the Blob object
                const url = URL.createObjectURL(blob);

                // Set the URL in state to display the resume
                setResumeData(url);
            })
            .catch((error) => {
                console.error(error);
            });

    }, [file]);

    const uploadresume = () => {
        // console.log(file);
        if (!file) {
            toast.error('No file selected for upload');
            return;
        }

        const email = localStorage.getItem("email");

        if (!email) {
            toast.error('No email found in localStorage');
            return;
        }

        const formdata = new FormData();
        formdata.append('file', file);
        formdata.append('email', email);

        axios
            .post('http://209.182.232.22:4000/upload', formdata)
            .then((response) => {
                const { data } = response;
                console.log(data);
                if (data.status === "OK") {
                    toast.success(data.message); // Display a success message
                    window.location.reload();
                } else {
                    toast.error(data.error || 'Upload failed'); // Display an error message
                }
            })
            .catch((err) => {
                console.error(err);
                toast.error('Upload failed');
            });
    };


    const deleteResume = () => {
        const confirmed = window.confirm("Are you sure you want to delete your resume?");
        setUserid(profiledata.UserID);
        if (confirmed) {
            axios
                .delete(`http://209.182.232.22:4000/deleteResume/${userid}`)
                .then((response) => {
                    const { data } = response;

                    if (data.status === 'OK') {
                        toast.success(data.message);
                        alert(data.message);
                        setResumeData('');

                    } else {
                        toast.error(data.error || 'Deletion failed');

                    }
                    window.location.reload();
                })
                .catch((err) => {
                    console.error(err);
                    toast.error('Deletion failed');
                });
        }
    };


    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0];

        if (selectedFile) {
            setFile(selectedFile);
        } else {
            // Handle the case where no file is selected or e.target.files is null
            setFile(null); // Set the state to null or handle it as needed
        }
    };


    const updateprofile = async () => {

        try {
            const updatedData = {
                userid: profiledata.UserID,
                username: profiledata.Username,
                firstname: fname,
                lastname: lname,
                email: profiledata.Email,
                phone: pno,
                title: upTitle,
                city: city,
                state:state,
                area:area,
                zipcode:pincode,
            };

            const token = localStorage.getItem('token');
            // Send a PUT request to update the profile
            const response = await axios.post('http://209.182.232.22:4000:4000/update-profile', updatedData, {
                headers: {
                    Authorization: `Bearer ${token}`, // Include the user's token for authentication
                },
            });

            if (response.status === 200) {
                toast.success('Profile updated successfully');
                navigate('/dashboard');
            } else {
                throw new Error('Failed to update profile');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            toast.error('Failed to update profile');
        }
    };

    
    return (
        <div>
            <ToastContainer />
            <StickyNavbar />
            <div className="flex items-center w-full  justify-center p-6">


                <div className="mx-auto w-full max-w-[850px] mt-14 bg-white p-6">

                    <div className="mb-5 pt-3">
                        <label className="mb-5 block text-base font-semibold text-[#07074D] sm:text-xl">
                            Personal Info
                        </label>
                        <hr />
                        <div className="mb-5">
                        <label className="mb-3 block text-base font-medium text-[#07074D]">
                           Title
                        </label>
                        <MDBInput type="text" name="title" id="title" placeholder="Enter your Title"
                            value={upTitle} className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" onChange={(e)=> setUptitle(e.target.value)} />
                    </div>
                        <div className="-mx-3 flex flex-wrap">
                            <div className="w-full px-3 sm:w-1/2">
                                <div className="mb-5">
                                    <label className="mb-3 block text-base font-medium text-[#07074D]">
                                        Username
                                    </label>
                                    <MDBInput type="text" name="username" id="username" placeholder="Username"
                                        className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" readOnly value={profiledata.Username} />
                                </div>
                            </div>
                            <div className="w-full px-3 sm:w-1/2">
                                <div className="mb-5">
                                    <label className="mb-3 block text-base font-medium text-[#07074D]">
                                        First Name
                                    </label>
                                    <MDBInput type="text" name="fname" id="fname" placeholder="First Name"
                                        className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" value={fname} onChange={(e) => setFname(e.target.value)} />
                                </div>
                            </div>
                            <div className="w-full px-3 sm:w-1/2">
                                <div className="mb-5">
                                    <label className="mb-3 block text-base font-medium text-[#07074D]">
                                        Last Name
                                    </label>
                                    <MDBInput type="text" name="lname" id="lname" placeholder="Last Name" onChange={(e)=>setLname(e.target.value)}
                                        value={lname} className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                </div>
                            </div>
                            <div className="w-full px-3 sm:w-1/2">
                                <div className="mb-5">
                                    <label className="mb-3 block text-base font-medium text-[#07074D]">
                                        Mobile Number
                                    </label>
                                    <MDBInput type="number" name="phone" id="phone" placeholder="Mobile Number" onChange={(e)=>setPno(e.target.value)}
                                        value={pno} className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-5">
                        <label className="mb-3 block text-base font-medium text-[#07074D]">
                            Email Address
                        </label>
                        <MDBInput type="email" readOnly name="email" id="email" placeholder="Enter your email"
                            value={profiledata.Email} className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                    </div>
                    {/* <div className="-mx-3 flex flex-wrap">
                        <div className="w-full px-3 sm:w-1/2">
                            <div className="mb-5">
                                <label className="mb-3 block text-base font-medium text-[#07074D]">
                                    Password
                                </label>
                                <MDBInput type="password" name="password" id="password"
                                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                            </div>
                        </div>
                        <div className="w-full px-3 sm:w-1/2">
                            <div className="mb-5">
                                <label className="mb-3 block text-base font-medium text-[#07074D]">
                                    Confirm Password
                                </label>
                                <MDBInput type="password" name="confirmpassword" id="confirmpassword"
                                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                            </div>
                        </div>
                    </div> */}

                    <div className="mb-5 pt-3">
                        <label className="mb-5 block text-base font-semibold text-[#07074D] sm:text-xl">
                            Address Details
                        </label>
                        <div className="-mx-3 flex flex-wrap">
                            <div className="w-full px-3 sm:w-1/2">
                                <div className="mb-5">
                                    <MDBInput type="text" name="area" id="area" placeholder="Enter area"
                                        value={area} className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" onChange={(e)=>setArea(e.target.value)} />
                                </div>
                            </div>
                            <div className="w-full px-3 sm:w-1/2">
                                <div className="mb-5">
                                    <MDBInput type="text" name="city" id="city" placeholder="Enter city" value={city} onChange={(e)=>setCity(e.target.value)}
                                        className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                </div>
                            </div>
                            <div className="w-full px-3 sm:w-1/2">
                                <div className="mb-5">
                                    <MDBInput type="text" name="state" id="state" placeholder="Enter state" value={state} onChange={(e)=>setState(e.target.value)}
                                        className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                </div>
                            </div>
                            <div className="w-full px-3 sm:w-1/2">
                                <div className="mb-5">
                                    <MDBInput type="number" name="post-code" id="post-code" placeholder="Post Code" value={pincode} onChange={(e)=>setPincode(e.target.value)}
                                        className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="mb-5 pt-3">
                        <label className="mb-5 block text-base font-semibold text-[#07074D] sm:text-xl">
                            Resume
                        </label>
                        <div className="-mx-3 flex flex-wrap">
                            <div className="w-full px-3 sm:w-1/2">
                                <div className="mb-5">
                                    {resumeData ? (
                                        <>
                                            <h5>Resume 1</h5>
                                            <a href={resumeData} target="_blank" rel="noopener noreferrer" className='flex justify-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                                </svg>

                                            </a>
                                            <Button onClick={deleteResume}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                            </Button>
                                        </>
                                    ) : (
                                        <>

                                            <MDBFile
                                                label="Resume 1"
                                                size="sm"
                                                id="formFileSm"
                                                onChange={handleFileChange}
                                            />
                                            <MDBBtn onClick={uploadresume} className='mt-2 bg-red-600 text-white rounded-lg p-4'>Save Resume</MDBBtn>
                                        </>)}
                                </div>
                            </div>

                        </div>
                        <div className="w-full px-3 sm:w-1/2">
                            <div className="mb-5">
                                {/* <input type="file" name="resume2" id="resume2" placeholder="Enter city"
                                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" /> */}
                            </div>
                        </div>
                        {/* <div className="w-full px-3 sm:w-1/2">
                            <div className="mb-5">
                                <input type="file" name="resume3" id="resume3" placeholder="Enter state"
                                    className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md" />
                            </div>
                        </div> */}

                    </div>


                    <div>
                        <MDBBtn
                            className="hover:shadow-form w-full rounded-md bg-[#6A64F1] py-3 px-8 text-center text-base font-semibold text-white outline-none" onClick={updateprofile}>
                            Update Profile
                        </MDBBtn>
                    </div>

                </div>
            </div>


        </div>
    )
}

export default Profile