import React from 'react'

function Footer() {
  return (
    <div>
      
<footer className="bg-white dark:bg-gray-900 px-12">
    <div className="mx-auto w-full max-w-screen-xl">
      <div className="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
        <div className='text-center'>
            <img className='h-44 w-100 m-auto mb-2' src="./images/Role-hive-ftr-logo.png" alt="" />
            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white text-center">"Empowering Futures."</h2>
         
        </div>
        <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white text-left">Resources</h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium text-left">
                <li className="mb-4">
                    <a href="/login" className="hover:underline">My Account</a>
                </li>
                <li className="mb-4">
                    <a href="#" className="hover:underline">Support</a>
                </li>
                <li className="mb-4">
                    <a href="/login" className="hover:underline">Candidate</a>
                </li>
              
                <li className="mb-4">
                    <a href="#" className="hover:underline">Employees</a>
                </li>
                <li className="mb-4">
                    <a href="#" className="hover:underline">How It Works</a>
                </li>
            </ul>
        </div>
        <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white text-left">Pages</h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium text-left">
                <li className="mb-4">
                    <a href="#" className="hover:underline">Home</a>
                </li>
                <li className="mb-4">
                    <a href="#" className="hover:underline">About Us</a>
                </li>
                <li className="mb-4">
                    <a href="#" className="hover:underline">Services</a>
                </li>
                <li className="mb-4">
                    <a href="#" className="hover:underline">Find Jobs</a>
                </li>

             
                <li className="mb-4">
                    <a href="#" className="hover:underline">Contact Us</a>
                </li>
            </ul>
        </div>
        <div className='text-center'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15556.93957195947!2d77.6146134!3d12.892612!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15f1dc9a2097%3A0x6a061cb65116b313!2sMarixn%20Technologies%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1695031692528!5m2!1sen!2sin" width="100%" height="230px" style={{ border: '0' }} loading="lazy"></iframe>
   
        </div>
    </div>
    <div className="px-4 justify-center py-6 bg-gray-100 dark:bg-gray-900 md:flex md:items-center md:justify-center border-t-2">
      
        <div className="flex  mt-4 space-x-5 sm:justify-center md:mt-0">
        <h2 className="text-sm text-gray-500 dark:text-gray-300 sm:text-center flex gap-2">© 2023 Marixn Technologies Private Ltd.. All Rights Reserved.
        </h2>
        </div>
        
      </div>
    </div>
</footer>

    </div>
  )
}

export default Footer
