import About from './Pages/About'
import Employer from './Pages/Employer'
import Hero from './Pages/Hero'
import ImageCard from './Pages/ImageCard'
import JobContent from './Pages/JobContent'
import Joblist from './Pages/Joblist'
import Newsletter from './Pages/Newsletter'
import Portfolio from './Pages/Portfolio'
import Service from './Pages/Service'


const Home = () => {
  return (
    <div>
        <Hero/>
        <Employer />
        <About/>
        <ImageCard />
        <Service />
        <Joblist />
        {/* <Portfolio /> */}
        <JobContent/>
        <Newsletter />
    </div>
  )
}

export default Home