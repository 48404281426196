import axios from 'axios';
import { MDBBtn, MDBIcon, MDBInput } from 'mdb-react-ui-kit';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Login = () => {
  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('');
 
    const navigate = useNavigate();
  
    const handleLogin = async () => {
      
      const response = await fetch("http://209.182.232.22:4000/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email, password })
      });
    
      // alert(response);
      if (response.ok) {
        const data = await response.json();
        if(data.status == "Invalid Credentials")
        {
          toast(data.status);
        }else{
          localStorage.setItem('token', data.data); // Store token in localStorage
          localStorage.setItem('email', data.email); 
          toast("Login Successfull");
          if (data.usertype === "Admin") {
            navigate('/admindashboard'); // Redirect to the admin dashboard
          } else if (data.usertype === "User") {
            navigate('/profile'); 
          } else {
            console.error("Invalid user type");
          }
    
        }
      } else {
        console.error("Error:", response.statusText);
      }  
      
    };

  return (
    <section className="min-h-screen flex flex-col">
      <div className="flex flex-1 items-center justify-center">
      <div className="rounded-lg sm:border-2 px-4 lg:px-24 py-16 lg:max-w-xl sm:max-w-md w-full text-center">
        <h2 className='mb-4'>Sign In</h2>
  
      <img className='m-auto h-16 mb-4' src="https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png" alt="" />
      
      <MDBInput wrapperClass='mb-4' className='bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700' label='Email address' id='form1' type='email'  name="email" onChange={(e) => setEmail(e.target.value)}/>
      
      <MDBInput wrapperClass='mb-4' className='bg-gray-200 border-2 border-gray-100 focus:outline-none bg-gray-100 block w-full py-2 px-4 rounded-lg focus:border-gray-700' label='Password' id='form2' type='password' name="password" onChange={(e) => setPassword(e.target.value)} />

      <MDBBtn className="mb-4 w-100 border-2 border-gray-100 focus:outline-none bg-blue-700 text-white font-bold tracking-wider block w-full p-2 rounded-lg focus:border-gray-700 hover:bg-purple-700" onClick={handleLogin}>Sign in</MDBBtn>

      <div className="text-center">
            <Link to="/resetpassword" className="hover:underline">
              Forgot password?
            </Link>
          </div>
          <div className="text-center mt-12">
            <span>Don't have an account?</span>
            <Link
              to="/signup"
              className="font-light text-md text-indigo-600 underline font-semibold hover:text-indigo-800"
            >
              Create One
            </Link>
          </div>
        
      </div>
      </div>

    
         
    </section>
  );
};

export default Login;
