import React from 'react'
import { FaAngleDoubleRight } from "react-icons/fa";
import './css/Employer.css';
const Employer = () => {
  return (
    <div className='main-picsec bg-white'>
    <div className="imgsupport-heading"><span className='bg-orange-600 text-white p-2 font-bold rounded-md 4'>Dashboard</span>
    <h2 className='mt-2'>Elevating Careers, Empowering Futures – Together We Thrive!</h2>
    <h4 className='mb-4'>Find & Hire Experts.</h4></div>
<section className="pic-section pb-20">
  <div className="pic-container">
    <img src="./images/Candidate.jpg" alt="Pic 1" className="pic" />
     <div className="text"><a href='/login'>
      <h3>Candidate Dashboard</h3>
      <h4>Your Dream Career</h4>
      <p><FaAngleDoubleRight className='icon-doubleright'/>Just a Click Closer</p></a>
    </div>
  </div>
  <div className="pic-container">
    <img src="./images/Employer.jpg" alt="Pic 2" className="pic" />
    <div className="text"><a href='/login'>
      <h3>Employer Dashboard</h3>
      <h4>Your Perfect Team</h4>
      <p><FaAngleDoubleRight className='icon-doubleright'/>Click Away</p></a>
    </div>
  </div>
</section>
</div>
  )
}

export default Employer