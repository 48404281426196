import React from 'react';

interface SearchInputProps {
  searchInput: string;
  setSearchInput: (value: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({ searchInput, setSearchInput }) => {
  return (
    <div>
      <div className="w-full">
        <div className="w-[80%] mx-auto text-black">
          <div className="w-full">
            <div className="flex justify-center items-center">
              <input
                type="text"
                placeholder="Search by job title"
                onChange={(e) => setSearchInput(e.target.value)}
                className="w-full font-primary text-md font-semibold px-4 py-3 border-gray-100 bg-gray-100 shadow-lg outline-none"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchInput;
