import { Button } from 'antd';
import { MDBBtn } from 'mdb-react-ui-kit';
import React, { useEffect,useState } from 'react';
import { ToastContainer,toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface Job {
  Job_Title: string;
  _id: string;
  Company_Name: string;
  Job_Type: string;
  Location:string;
  Description:string;
  Job_ID:string;
}

interface JobListingsProps {
  jobs: Job[];
  currentPage: number;
  jobsPerPage: number;
  paginate: (pageNumber: number) => void;
}

const SearchResult: React.FC<JobListingsProps> = ({ jobs, currentPage, jobsPerPage, paginate }) => {
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = jobs.slice(indexOfFirstJob, indexOfLastJob);
  const [hasDisplayedToast, setHasDisplayedToast] = useState(false);
  const navigate = useNavigate();


  const applyjob = async (jobid:string) => {
    navigate(`/applyjob/${jobid}`);
  };
  
  return (
    <div className="p-12">
      <ul>
        {currentJobs.map((job) => (
          <li key={job._id} className="w-full">
            <div className="mx-auto w-[86%] p-6 shadow-lg">
              <div className="grid grid-cols-3">
                <div className="title">
                <h2>{job.Job_Title}</h2>
                </div>
                <div className="cname">
                <h2>{job.Company_Name}</h2>
                </div>
                <div className="location">
                <h2>{job.Location}</h2>
                </div>
              </div>
           <div className="des">
           <p className='text-left'> {job.Description}</p>
           </div>

           <MDBBtn
        className="className='mb-4 w-100 border-2 border-gray-100 focus:outline-none bg-blue-700 text-white font-bold tracking-wider block  p-2 rounded-lg focus:border-gray-700 hover:bg-purple-700"
        onClick={() => applyjob(job.Job_ID)} // Pass the jobid to the applyjob function
      >
        View Details
      </MDBBtn>
            </div>
          </li>
        ))}
      </ul>

      <ul className="mt-5 pagination justify-center flex-wrap flex w-[80%] mx-auto gap-2">
        {[...Array(Math.ceil(jobs.length / jobsPerPage))].map((_, index) => (
          <li key={index} className="page-item">
            <button
              onClick={() => paginate(index + 1)}
              className={`page-link ${
                currentPage === index + 1
                  ? 'bg-blue-500 text-white'
                  : 'hover:bg-blue-500 hover:text-white text-blue-500'
              } border border-blue-500 rounded-md px-3 py-1`}
            >
              {index + 1}
            </button>
          </li>
        ))}
      </ul>
      <ToastContainer />
    </div>
  );
};

export default SearchResult;
