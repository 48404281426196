import React, { useEffect, useState } from 'react'
import AdminNavbar from './AdminNavbar'
import Users from './Users'
import axios from 'axios';

const Admindashboard = () => {
    const [adminData, setAdminData] = useState([]);
  const email = localStorage.getItem('email');
  const token = localStorage.getItem('token');
  const [totalapplications,setTotalapplications] = useState('');
  const [jobs,setJobs] = useState('');
  const [totalusers,setTotalusers] = useState('');
    useEffect(() => {
        axios.get('http://209.182.232.22:4000/getappdata') // Use Axios post request
          .then((response) => {
            if (!response.data) {
              throw new Error('Failed to fetch admin data');
            }
            // console.log(response.data);
            // setAdminData(response.data);
            setTotalapplications(response.data.totalApplications);
            setJobs(response.data.jobApplications);
            setTotalusers(response.data.users);
          })
          .catch((error) => {
            console.error('Error fetching admin data:', error);
          });
      }, [email, token]);
    
  return (
    <div>
      <AdminNavbar />

<div className="pt-4" >
<div className="flex items-center bg-white-700">
    <div className="container max-w-6xl px-5 mx-auto mt-24">
        <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-4">
            <div className="p-5 bg-white rounded shadow-sm dark:bg-gray-800">
                <div className="text-base text-gray-400 dark:text-gray-300 text-left">Number of Applications</div>
                <div className="flex items-center pt-1">
                    <div className="text-2xl font-bold text-gray-900 dark:text-gray-100">{totalapplications}</div>
                    
                </div>
            </div>
            <div className="p-5 bg-white rounded shadow-sm dark:bg-gray-800">
                <div className="text-base text-gray-400 dark:text-gray-300 text-left">Total Jobs Posted</div>
                <div className="flex items-center pt-1">
                    <div className="text-2xl font-bold text-gray-900 dark:text-gray-100">{jobs}</div>
                    
                </div>
            </div>
            <div className="p-5 bg-white rounded shadow-sm dark:bg-gray-800">
                <div className="text-base text-gray-400 dark:text-gray-300 text-left">Total Users</div>
                <div className="flex items-center pt-1">
                    <div className="text-2xl font-bold text-gray-900 dark:text-gray-100">{totalusers}</div>
                    
                </div>
            </div>
            {/* <div className="p-5 bg-white rounded shadow-sm dark:bg-gray-800">
                <div className="text-base text-gray-400 dark:text-gray-300 text-left">Scheduled Applications</div>
                <div className="flex items-center pt-1">
                    <div className="text-2xl font-bold text-gray-900 dark:text-gray-100">10</div>
                    
                </div>
            </div> */}
        </div>
    </div>
</div>
</div>

<Users />
</div>

  )
}

export default Admindashboard