import { Button, Navbar, Typography } from "@material-tailwind/react";
import Topbar from "./Topbar";


const StickyNavbar = () => {
  return (
    <>
     <Topbar/>
     <Navbar className=" z-10 sticky top-0 z-10 max-w-full px-12 py-1 lg:px-20 lg:py-2 justify-between items-center flex ">
      <div className="text-white text-2xl font-bold">
        <img
          src="./images/Role-hive-Logo-1.png"
          alt="logo"
          className="h-110 w-110"
        />
      </div>
      <div className="border-t-2 border-b-2 border-black p-2">
        <ul className="flex space-x-4 items-center">
          <li className="relative group">
            <Typography
              as="a"
              href="/"
              className="mr-4 cursor-pointer py-1.5 font-medium text-orange-800 hover:text-orange-700 underline"
            >
              Home
            </Typography>
          </li>

          <li className="relative group">
            <Typography
              as="a"
              href="about"
              className="mr-4 cursor-pointer py-1.5 font-medium text-black hover:text-blue-700"
            >
              About Us
            </Typography>
          </li>
          
       
          <li className="relative group">
            <Typography
              as="a"
              href="carrer"
              className="mr-4 cursor-pointer py-1.5 font-medium text-black hover:text-blue-700"
            >
              Carrers
            </Typography>
          </li>

          <li className="relative group">
            <Typography
              as="a"
              href="#"
              className="mr-4 cursor-pointer py-1.5 font-medium text-black hover:text-blue-700"
            >
             Blog
            </Typography>
          </li>
          <li className="relative group">
            <Button
              variant="gradient"
              size="sm"
              fullWidth
              className="text-white bg-orange-600 hover:bg-amber-400 rounded-lg"
            >
              <span>Contact Us</span>
            </Button>
          </li>
        </ul>
      </div>
      <div>
      <Button
              variant="gradient"
              size="sm"
              fullWidth
              className="text-white bg-orange-600 hover:bg-amber-400 rounded-lg"
            >
              <span><a href="+919364001900">Ph no : (+91) 9364001900</a></span>
            </Button>
      </div>
    </Navbar>
     </>
   
  );
};

export default StickyNavbar;
