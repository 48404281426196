import { Button} from "@material-tailwind/react";
import React from 'react'

const JobContent = () => {
  return (
   <section className='w-full bg-grey p-11 text-left'>
        <div className="grid grid-cols-2 px-11">
            <div className="content ">
            <h2>Passionate Professional? Explore Opportunities Tailored Just for You</h2>
          <div className="divider1"></div>
          <ul>
            <li className='text-blue-700'>Create an account</li>
            <p className='pb-6'>Unlock Limitless Opportunities! Sign Up Now to Shape Your Future</p>
            <li className='text-blue-700'>Search for openings</li>
            <p className='pb-6'>Explore Exciting Career Pathways Tailored to Your Expertise and Passion</p>
            <li className='text-blue-700'>Save & Apply</li>
            <p className='pb-6'>Secure Your Future! Save Your Resume and Apply with Confidence to Your Niche Opportunities</p>
          </ul>
          <Button className='variant="gradient"
              size="sm"
              fullWidth
              className="text-white bg-orange-600 hover:bg-amber-400 rounded-lg"'>View More</Button>
            </div>
            <div className="image px-11">
                <img src="./images/img-job-search.png" alt="" />
            </div>
        </div>
   </section>
  )
}

export default JobContent