import "./App.css";
import Footer from "./Components/Common/Footer";
import StickyNavbar from "./Components/Common/StickyNavbar";
import Topbar from "./Components/Common/Topbar";
import Home from "./Components/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Aboutus from "./Components/Aboutus";
import Carrer from "./Components/Pages/Carrer";
import Login from "./Components/Authentication/Login";
import Dashboard from "./Components/Dashboard";
import Signup from "./Components/Authentication/Signup";
import ResetPassword from "./Components/Authentication/ResetPassword";
import UserDashboard from "./Components/UserDashboard/UserDashboard";
import ProtectedRoute from "./Components/Authentication/ProtectedRoute";
import Jobs from "./Components/UserDashboard/Jobs";
import Profile from "./Components/UserDashboard/Profile";
import Admindashboard from "./Components/AdminDashboard/Admindashboard";
import Users from "./Components/AdminDashboard/Users";
import Listjobs from "./Components/AdminDashboard/Listjobs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewJob from "./Components/Pages/ViewJobDescriptio";

function App() {
  return (
    <div className="App bg-slate-50">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <StickyNavbar />
                <Home />
                <Footer />
              </>
            }
          />
          <Route
            path="about"
            element={
              <>
                <StickyNavbar />
                <Aboutus />
                <Footer />
              </>
            }
          />
          <Route
            path="carrer"
            element={
              <>
                <StickyNavbar />
                <Carrer />
                <Footer />
              </>
            }
          />
          <Route
            path="login"
            element={
              <>
                <Login />
              </>
            }
          />
          <Route
            path="signup"
            element={
              <>
                <Signup />
              </>
            }
          />
          <Route
            path="resetpassword"
            element={
              <>
                <ResetPassword />
              </>
            }
          />
          <Route
            path="dashboard"
            element={
              <>
                <ProtectedRoute element={<UserDashboard />} />
              </>
            }
          />

          <Route
            path="/jobs"
            element={
              <>
                <ProtectedRoute element={<Jobs />} />
              </>
            }
          />

          <Route
            path="/profile"
            element={
              <>
                <ProtectedRoute element={<Profile />} />
              </>
            }
          />

          <Route
            path="/admindashboard"
            element={
              <>
                <ProtectedRoute element={<Admindashboard />} />
              </>
            }
          />

        <Route
          path="/applyjob/:jobid"
          element={
            <>
              <ProtectedRoute element={<ViewJob />} />
            </>
          }
        />



          <Route
            path="/users"
            element={
              <>
                <ProtectedRoute element={<Users />} />
              </>
            }
          />
          <Route
            path="/listjobs"
            element={
              <>
                <ProtectedRoute element={<Listjobs />} />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
