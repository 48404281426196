import React, { ReactElement, useEffect, useState } from "react";
import { Navigate,useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface ProtectedRouteProps {
  element: ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    if (!token) {
      setIsAuthenticated(false);
    } else {
      axios
        .post("http://209.182.232.22:4000/validate", { token, email }, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response => {
          const data = response.data;
          if (data.error === "Token Expired. Please login again.") {
            toast(data.error);
            navigate("/login"); // Redirect to the login page
          } else {
            setIsAuthenticated(true);
          }
        })
        .catch(error => {
          console.error("Axios error:", error);
        });
    }
  }, [navigate]);

  if (isAuthenticated === null) {
    // Waiting for the API call to complete
    return null;
  } else if (isAuthenticated) {
    return element;
  } else {
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;
