import React from 'react'

const Hero = () => {
  return (
    <section className="bg-grey dark:bg-gray-900 bg-hero">
    <div className="grid max-w-screen-xl px-4 py-4 mx-auto lg:gap-8 xl:gap-0 lg:py-8 lg:grid-cols-12">
        <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">Find Your Best Jobs</h1>
            <h2 className="max-w-2xl mb-4 text-4xl font-bold tracking-tight leading-none md:text-1xl xl:text-2xl dark:text-white">Role Hive:Where Niche Talents and Dream Roles Unite</h2>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Let our job portal be your guiding light towards a brighter career. Create your profile and seize the incredible opportunities waiting for you. Don't miss out!</p>
            <a href="#" className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-orange-600 hover:bg-amber-400 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                Learn More
                <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </a>
            <a href="#" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-orange-600 focus:ring-4 focus:bg-blue-700 dark:text-white dark:border-gray-700 dark:hover:bg-blue-700 dark:focus:bg-blue-700 bg-amber-400">
                Speak to Sales
            </a> 
        </div>
        <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
  <img src="./assets/herobannaer.png" alt="mockup" className='' />
</div>
          
    </div>
</section>
  )
}

export default Hero